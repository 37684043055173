import _errors from "./errors";
import _types from "./types";
import _reader from "./reader";
import _writer from "./writer";
var exports = {};
// Copyright 2011 Mark Cavage <mcavage@gmail.com> All rights reserved.
var errors = _errors;
var types = _types;
var Reader = _reader;
var Writer = _writer; // --- Exports

exports = {
  Reader: Reader,
  Writer: Writer
};

for (var t in types) {
  if (types.hasOwnProperty(t)) exports[t] = types[t];
}

for (var e in errors) {
  if (errors.hasOwnProperty(e)) exports[e] = errors[e];
}

export default exports;