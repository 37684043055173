var exports = {};
// Copyright 2011 Mark Cavage <mcavage@gmail.com> All rights reserved.
exports = {
  newInvalidAsn1Error: function (msg) {
    var e = new Error();
    e.name = "InvalidAsn1Error";
    e.message = msg || "";
    return e;
  }
};
export default exports;