import * as _assert2 from "assert";

var _assert = "default" in _assert2 ? _assert2.default : _assert2;

import * as _saferBuffer2 from "safer-buffer";

var _saferBuffer = "default" in _saferBuffer2 ? _saferBuffer2.default : _saferBuffer2;

import _types from "./types";
import _errors from "./errors";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
// Copyright 2011 Mark Cavage <mcavage@gmail.com> All rights reserved.
var assert = _assert;
var Buffer = _saferBuffer.Buffer;
var ASN1 = _types;
var errors = _errors; // --- Globals

var newInvalidAsn1Error = errors.newInvalidAsn1Error;
var DEFAULT_OPTS = {
  size: 1024,
  growthFactor: 8
}; // --- Helpers

function merge(from, to) {
  assert.ok(from);
  assert.equal(typeof from, "object");
  assert.ok(to);
  assert.equal(typeof to, "object");
  var keys = Object.getOwnPropertyNames(from);
  keys.forEach(function (key) {
    if (to[key]) return;
    var value = Object.getOwnPropertyDescriptor(from, key);
    Object.defineProperty(to, key, value);
  });
  return to;
} // --- API


function Writer(options) {
  options = merge(DEFAULT_OPTS, options || {});
  (this || _global)._buf = Buffer.alloc(options.size || 1024);
  (this || _global)._size = (this || _global)._buf.length;
  (this || _global)._offset = 0;
  (this || _global)._options = options; // A list of offsets in the buffer where we need to insert
  // sequence tag/len pairs.

  (this || _global)._seq = [];
}

Object.defineProperty(Writer.prototype, "buffer", {
  get: function () {
    if ((this || _global)._seq.length) throw newInvalidAsn1Error((this || _global)._seq.length + " unended sequence(s)");
    return (this || _global)._buf.slice(0, (this || _global)._offset);
  }
});

Writer.prototype.writeByte = function (b) {
  if (typeof b !== "number") throw new TypeError("argument must be a Number");

  this._ensure(1);

  (this || _global)._buf[(this || _global)._offset++] = b;
};

Writer.prototype.writeInt = function (i, tag) {
  if (typeof i !== "number") throw new TypeError("argument must be a Number");
  if (typeof tag !== "number") tag = ASN1.Integer;
  var sz = 4;

  while (((i & 4286578688) === 0 || (i & 4286578688) === 4286578688 >> 0) && sz > 1) {
    sz--;
    i <<= 8;
  }

  if (sz > 4) throw newInvalidAsn1Error("BER ints cannot be > 0xffffffff");

  this._ensure(2 + sz);

  (this || _global)._buf[(this || _global)._offset++] = tag;
  (this || _global)._buf[(this || _global)._offset++] = sz;

  while (sz-- > 0) {
    (this || _global)._buf[(this || _global)._offset++] = (i & 4278190080) >>> 24;
    i <<= 8;
  }
};

Writer.prototype.writeNull = function () {
  this.writeByte(ASN1.Null);
  this.writeByte(0);
};

Writer.prototype.writeEnumeration = function (i, tag) {
  if (typeof i !== "number") throw new TypeError("argument must be a Number");
  if (typeof tag !== "number") tag = ASN1.Enumeration;
  return this.writeInt(i, tag);
};

Writer.prototype.writeBoolean = function (b, tag) {
  if (typeof b !== "boolean") throw new TypeError("argument must be a Boolean");
  if (typeof tag !== "number") tag = ASN1.Boolean;

  this._ensure(3);

  (this || _global)._buf[(this || _global)._offset++] = tag;
  (this || _global)._buf[(this || _global)._offset++] = 1;
  (this || _global)._buf[(this || _global)._offset++] = b ? 255 : 0;
};

Writer.prototype.writeString = function (s, tag) {
  if (typeof s !== "string") throw new TypeError("argument must be a string (was: " + typeof s + ")");
  if (typeof tag !== "number") tag = ASN1.OctetString;
  var len = Buffer.byteLength(s);
  this.writeByte(tag);
  this.writeLength(len);

  if (len) {
    this._ensure(len);

    (this || _global)._buf.write(s, (this || _global)._offset);

    (this || _global)._offset += len;
  }
};

Writer.prototype.writeBuffer = function (buf, tag) {
  if (typeof tag !== "number") throw new TypeError("tag must be a number");
  if (!Buffer.isBuffer(buf)) throw new TypeError("argument must be a buffer");
  this.writeByte(tag);
  this.writeLength(buf.length);

  this._ensure(buf.length);

  buf.copy((this || _global)._buf, (this || _global)._offset, 0, buf.length);
  (this || _global)._offset += buf.length;
};

Writer.prototype.writeStringArray = function (strings) {
  if (!strings instanceof Array) throw new TypeError("argument must be an Array[String]");
  var self = this || _global;
  strings.forEach(function (s) {
    self.writeString(s);
  });
}; // This is really to solve DER cases, but whatever for now


Writer.prototype.writeOID = function (s, tag) {
  if (typeof s !== "string") throw new TypeError("argument must be a string");
  if (typeof tag !== "number") tag = ASN1.OID;
  if (!/^([0-9]+\.){3,}[0-9]+$/.test(s)) throw new Error("argument is not a valid OID string");

  function encodeOctet(bytes, octet) {
    if (octet < 128) {
      bytes.push(octet);
    } else if (octet < 16384) {
      bytes.push(octet >>> 7 | 128);
      bytes.push(octet & 127);
    } else if (octet < 2097152) {
      bytes.push(octet >>> 14 | 128);
      bytes.push((octet >>> 7 | 128) & 255);
      bytes.push(octet & 127);
    } else if (octet < 268435456) {
      bytes.push(octet >>> 21 | 128);
      bytes.push((octet >>> 14 | 128) & 255);
      bytes.push((octet >>> 7 | 128) & 255);
      bytes.push(octet & 127);
    } else {
      bytes.push((octet >>> 28 | 128) & 255);
      bytes.push((octet >>> 21 | 128) & 255);
      bytes.push((octet >>> 14 | 128) & 255);
      bytes.push((octet >>> 7 | 128) & 255);
      bytes.push(octet & 127);
    }
  }

  var tmp = s.split(".");
  var bytes = [];
  bytes.push(parseInt(tmp[0], 10) * 40 + parseInt(tmp[1], 10));
  tmp.slice(2).forEach(function (b) {
    encodeOctet(bytes, parseInt(b, 10));
  });
  var self = this || _global;

  this._ensure(2 + bytes.length);

  this.writeByte(tag);
  this.writeLength(bytes.length);
  bytes.forEach(function (b) {
    self.writeByte(b);
  });
};

Writer.prototype.writeLength = function (len) {
  if (typeof len !== "number") throw new TypeError("argument must be a Number");

  this._ensure(4);

  if (len <= 127) {
    (this || _global)._buf[(this || _global)._offset++] = len;
  } else if (len <= 255) {
    (this || _global)._buf[(this || _global)._offset++] = 129;
    (this || _global)._buf[(this || _global)._offset++] = len;
  } else if (len <= 65535) {
    (this || _global)._buf[(this || _global)._offset++] = 130;
    (this || _global)._buf[(this || _global)._offset++] = len >> 8;
    (this || _global)._buf[(this || _global)._offset++] = len;
  } else if (len <= 16777215) {
    (this || _global)._buf[(this || _global)._offset++] = 131;
    (this || _global)._buf[(this || _global)._offset++] = len >> 16;
    (this || _global)._buf[(this || _global)._offset++] = len >> 8;
    (this || _global)._buf[(this || _global)._offset++] = len;
  } else {
    throw newInvalidAsn1Error("Length too long (> 4 bytes)");
  }
};

Writer.prototype.startSequence = function (tag) {
  if (typeof tag !== "number") tag = ASN1.Sequence | ASN1.Constructor;
  this.writeByte(tag);

  (this || _global)._seq.push((this || _global)._offset);

  this._ensure(3);

  (this || _global)._offset += 3;
};

Writer.prototype.endSequence = function () {
  var seq = (this || _global)._seq.pop();

  var start = seq + 3;
  var len = (this || _global)._offset - start;

  if (len <= 127) {
    this._shift(start, len, -2);

    (this || _global)._buf[seq] = len;
  } else if (len <= 255) {
    this._shift(start, len, -1);

    (this || _global)._buf[seq] = 129;
    (this || _global)._buf[seq + 1] = len;
  } else if (len <= 65535) {
    (this || _global)._buf[seq] = 130;
    (this || _global)._buf[seq + 1] = len >> 8;
    (this || _global)._buf[seq + 2] = len;
  } else if (len <= 16777215) {
    this._shift(start, len, 1);

    (this || _global)._buf[seq] = 131;
    (this || _global)._buf[seq + 1] = len >> 16;
    (this || _global)._buf[seq + 2] = len >> 8;
    (this || _global)._buf[seq + 3] = len;
  } else {
    throw newInvalidAsn1Error("Sequence too long");
  }
};

Writer.prototype._shift = function (start, len, shift) {
  assert.ok(start !== undefined);
  assert.ok(len !== undefined);
  assert.ok(shift);

  (this || _global)._buf.copy((this || _global)._buf, start + shift, start, start + len);

  (this || _global)._offset += shift;
};

Writer.prototype._ensure = function (len) {
  assert.ok(len);

  if ((this || _global)._size - (this || _global)._offset < len) {
    var sz = (this || _global)._size * (this || _global)._options.growthFactor;
    if (sz - (this || _global)._offset < len) sz += len;
    var buf = Buffer.alloc(sz);

    (this || _global)._buf.copy(buf, 0, 0, (this || _global)._offset);

    (this || _global)._buf = buf;
    (this || _global)._size = sz;
  }
}; // --- Exported API


exports = Writer;
export default exports;