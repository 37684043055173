import _index from "./ber/index";
var exports = {};
// Copyright 2011 Mark Cavage <mcavage@gmail.com> All rights reserved.
// If you have no idea what ASN.1 or BER is, see this:
// ftp://ftp.rsa.com/pub/pkcs/ascii/layman.asc
var Ber = _index; // --- Exported API

exports = {
  Ber: Ber,
  BerReader: Ber.Reader,
  BerWriter: Ber.Writer
};
export default exports;
export const BerReader = exports.BerReader;
const _Ber = exports.Ber;
export { _Ber as Ber };